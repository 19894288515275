import styled from "styled-components"
import Colors from "ui/colors"

const Styled = {
  Partners: styled.div`
    text-align: center;

    p {
      font-size: 24px;
      color: ${Colors.darkGray};
      line-height: 1.5;
      max-width: 975px;
      margin: 50px auto;
      font-weight: 100;
    }
  `,

  PartnerLogos: styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-gap: 100px;
    align-items: center;
  `,

  PartnerLogo: styled.div`
    width: 100%;
  `,
}

export default Styled

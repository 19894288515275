import React from "react"

// Components
import { Footer, Layout, SEO } from "../components/common"
import { Partners } from "components/partners-page"

const PartnersPage = () => (
  <Layout>
    <SEO title="Partners" />
    <Partners />
    <Footer heading="Partner with us" />
  </Layout>
)

export default PartnersPage

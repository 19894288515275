import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Components
import { Title } from "src/components/typography"
import { Container, Section } from "src/components/common"

// Styles
import Styled from "./partners.styled"

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      partnerLogos: allFile(
        filter: {
          relativeDirectory: { eq: "partners" }
          extension: { regex: "/(png)/" }
        }
      ) {
        ...PartnerLogos
      }

      pageText: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(partners)/" } }
      ) {
        edges {
          node {
            frontmatter {
              pageTitle
            }
          }
        }
      }
    }
  `)

  const { pageTitle, partnersText } = data.pageText.edges[0].node.frontmatter

  const renderPartnerLogos = () =>
    data.partnerLogos.edges.map(edge => {
      const imageData = edge.node

      return (
        <Styled.PartnerLogo key={imageData.name}>
          <Img
            alt={`${imageData.name}`}
            fluid={imageData.childImageSharp.fluid}
          />
        </Styled.PartnerLogo>
      )
    })

  return (
    <Styled.Partners>
      <Section>
        <Title level={3} blue>
          {pageTitle}
        </Title>
        <p>{partnersText}</p>
        <Container maxWidth={750}>
          <Styled.PartnerLogos>{renderPartnerLogos()}</Styled.PartnerLogos>
        </Container>
      </Section>
    </Styled.Partners>
  )
}

export default Partners
